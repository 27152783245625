import { Grid, Typography } from '@mui/material';
import React, { ReactElement, useContext, useEffect, useState } from 'react';

import CompanyCard from '../../components/company-card';
import { Company } from '../../models/company.model';
import { Context } from '../../store';
import { companyArr } from '../../data/company';
import AdsCard from '../../components/ads-card';


/** Returns a running number which resets per month */
const getRunningCounter = () => {
  const currentDate = new Date();
  const currentDayOfTheMonth = currentDate.getDate();

  // 0 - 23
  const currentHour = currentDate.getHours();

  // Add the total day hours together with current date's hour. Else it will screw up when the verified local company count cross 24
  return Math.abs(currentDayOfTheMonth - 1) * 24 + currentHour;
};

/** Rotates the given array with the running counter number to ensure round robin display of companies per hour */
const rotateCompanyArrInplaceWithRunningCounter = (
  inputArr: Array<Company>
) => {
  // Nothing to rotate if less than 2 items
  if (inputArr.length < 2) {
    return;
  }

  const currentIndex = getRunningCounter() % inputArr.length;

  if (currentIndex > 0) {
    const temp = inputArr.splice(0, currentIndex);
    inputArr.push(...temp);
  }
};

const rotateVerifiedCompanyArrWithRunningCounter = (
  inputArr: Array<Company>
) => {
  const verifiedLocalCompaniesArr: Array<Company> = [];
  const remainingCompaniesArr: Array<Company> = [];

  inputArr.forEach((c) => {
    if (c.verified && (c.tags || []).indexOf('Singapore') >= 0) {
      verifiedLocalCompaniesArr.push(c);
    } else {
      remainingCompaniesArr.push(c);
    }
  });

  rotateCompanyArrInplaceWithRunningCounter(verifiedLocalCompaniesArr);

  return verifiedLocalCompaniesArr.concat(remainingCompaniesArr);
};

const CompanyListing = (): ReactElement => {
  const [state, dispatch] = useContext(Context);
  const [displayfilteredCompanies, setDisplayFilteredCompanies] = useState<Company[]>([]);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    let newFilter;
    const pref = state.companyFilter.preference || '';
    if (pref != '' && pref.length > 0) {
      // If filter is applied, apply filter first
      const filteredCompanies = companyArr.filter((x) =>
        x.tags?.includes(pref)
      );
      // Then rotate the filtered companies
      const filteredAndRotatedCompanies = rotateVerifiedCompanyArrWithRunningCounter(filteredCompanies);
      newFilter = filteredAndRotatedCompanies;
    } else {
      // No filter
      // Use rotated companies when there's no filter selected
      const rotatedCompanies = rotateVerifiedCompanyArrWithRunningCounter(companyArr);
      newFilter = rotatedCompanies;
    }

    if (JSON.stringify(newFilter) !== JSON.stringify(displayfilteredCompanies)) {
      setDisplayFilteredCompanies(newFilter);
    }
    setIsLoaded(true);
  }, [companyArr, state.companyFilter.preference]);

  if (displayfilteredCompanies == null || displayfilteredCompanies.length <= 0) {
    if (!isLoaded) {
      return <></>;
    }
    return <Typography>No matching company found</Typography>;
  }

  return (
    <Grid container spacing={2}>
      {displayfilteredCompanies.map((x) => (
        <Grid key={x.name} item xs={12} sm={6} md={4} lg={3}>
          <CompanyCard {...x}></CompanyCard>
        </Grid>
      ))}
      <Grid key={'ads'} item xs={12} sm={6} md={4} lg={3}>
       <AdsCard />
      </Grid>
    </Grid>
  );
};

export default CompanyListing;
