import {
  Box,
  Card,
  CardContent,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { ReactElement } from 'react';
import ContactNum from './contact-num';
import { CustomGtagCategory } from '../constants/gtag-category';

const textStyle: React.CSSProperties = {
  textAlign: 'center',
  fontSize: '20px'
};

const AdsCard = (): ReactElement => {
  const theme = useTheme();

  const cardMinHeight = useMediaQuery(theme.breakpoints.up('sm'))
    ? '310px'
    : '200px';

  return (
    <Card variant="outlined">
      <Box minHeight={cardMinHeight} style={{ display:'flex', alignItems:'center', justifyContent:'center' }}>
        <CardContent>
          <Typography style={textStyle}>Are you a funeral services provider?</Typography>
          <br />
          <Typography style={textStyle}>Partner with SFD now</Typography>
          <Typography style={textStyle}>
            <div>
              <span aria-hidden style={{ display: 'inline-block' }}>
                Call&nbsp;
              </span>
              <span style={{ display: 'inline-block' }}>
                <ContactNum
                  phoneNumber="92366269"
                  phoneNumGtagCategory={CustomGtagCategory.SFD_Phone_Link}
                ></ContactNum>
              </span>
            </div>
          </Typography>
        </CardContent>
      </Box>
    </Card>
  );
};

export default AdsCard;
