import { CategoryEnum } from './category';
import { CountryEnum } from './country';
import { EthnicEnum } from './ethnic';
import { ReligionEnum } from './religion';

export type PreferenceLabels = ReligionEnum | CountryEnum | CategoryEnum | EthnicEnum;
export type PreferenceKeys = keyof (typeof ReligionEnum & typeof CountryEnum & typeof CategoryEnum & typeof EthnicEnum);

export const PreferenceKeyLabelMap = [ReligionEnum, CountryEnum, CategoryEnum, EthnicEnum].reduce((acc, curr) => {
  Object.keys(curr).forEach(k => {
    acc[k] = curr[k];
  });
  return acc;
}, {});