import React from 'react';
import { Box, Typography } from '@mui/material';

export interface PillData {
  text: string;
  textColor: string;
  backgroundImage: string;
  fallbackBgColor: string;
}

const Pill = (props: PillData) => {
  return (
    <Box
      className="pill"
      bgcolor={props.fallbackBgColor}
      padding="4px 10px"
      borderRadius="14px"
      color={props.textColor}
      fontStyle="italic"
      sx={{
        backgroundImage: props.backgroundImage,
      }}
    >
      <Typography variant="body2">{props.text}</Typography>
    </Box>
  );
};

export default Pill;
