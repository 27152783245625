import { Box } from '@mui/material';
import React, { ReactElement } from 'react';
import GtagNavigate from '../../components/gtag-navigate';
import { CustomGtagCategory } from '../../constants/gtag-category';

const Footer = (): ReactElement => {
  return (
    <Box width="100%" bottom="0">
      {/* <Box p={6} textAlign="center" display="flex" justifyContent="center" flexWrap="wrap">
        <Box marginX={1}>
          <GtagNavigate
            category={CustomGtagCategory.About_Us_Link}
            to="/about-us/"
          >
            <span>About Us</span>
          </GtagNavigate>
        </Box>

        <Box marginX={1}>
          <GtagNavigate
            category={CustomGtagCategory.Family_Link}
            to="/family/"
          >
            <span>For Family</span>
          </GtagNavigate>
        </Box>

        <Box marginX={1}>
          <GtagNavigate
            category={CustomGtagCategory.Funeral_Director_Link}
            to="/funeral-director/"
          >
            <span>For Funeral Director</span>
          </GtagNavigate>
        </Box>
      </Box> */}

      <Box p={3} />

      {/* Deprecated */}
      {/* <Box p={4} textAlign="center">
        <Typography variant="h6">
          <span style={{ display: 'inline-block', marginRight: '4px' }}>
            © Copyright Singapore Funeral Directory 2022.
          </span>
          <span style={{ display: 'inline-block' }}>All Rights Reserved</span>
        </Typography>
      </Box> */}
    </Box>
  );
};

export default Footer;
