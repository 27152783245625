import {
  OptimizeImageDataItem,
} from '../constants/image-optimization';

export const getOptimizedImgPath = (
  fileName: string,
  appendFileName?: string
): string => {
  let optimizedFileName = fileName;

  // Remove original file extension (if exist)
  const dotIndex = optimizedFileName.lastIndexOf('.');
  if (dotIndex >= 0) {
    // Strip away the previous format (since it is reoptimized)
    optimizedFileName = optimizedFileName.substring(0, dotIndex);
  }
  // All optimized images are jpeg, hence add back .jpeg extension
  optimizedFileName = `${optimizedFileName}.jpeg`;

  if (appendFileName) {
    optimizedFileName = optimizedFileName.replace(
      /(\..+)$/,
      (match, ext) => `${appendFileName}${ext}`
    );
  }

  return `/images/optimized/${optimizedFileName}`;
};

export const getOptimizedMetaImgPath = (
  fileName: string,
  shape: string,
  suffix: string
): string => getOptimizedImgPath(fileName, `-${shape}${suffix}`);

export const generateScaleSrcSet = (
  shape: string,
  targetImgData: OptimizeImageDataItem[],
  fileName?: string
): [string, string] => {
  if (fileName == null || fileName === '') {
    return ['', ''];
  }

  const scaleSrcSet = targetImgData
    .map(
      ({ suffix, scale }) =>
        `${getOptimizedMetaImgPath(fileName, shape, suffix)} ${scale}x`
    )
    .join(', ');

  return [scaleSrcSet, getOptimizedImgPath(fileName)];
};
