import { Actions, ActionTypes, SetCompanyFilterAction } from './actions';
import { InitialState, State } from './state';

export const Reducer = (
  state: State = InitialState,
  action: Actions
): State => {
  switch (action.type) {
    case ActionTypes.SET_COMPANY_FILTER:
      return {
        ...state,
        companyFilter: (action as SetCompanyFilterAction).companyFilter,
      };
    case ActionTypes.RESET_COMPANY_FILTER:
      return {
        ...state,
        companyFilter: {},
      };
    default:
      throw new Error(`Unhandled action in reducer: ${action.type}`);
  }
};
