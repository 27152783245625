export enum ReligionEnum {
  Buddhist = 'Buddhist',
  Taoist = 'Taoist',
  Christian = 'Christian',
  Catholic = 'Catholic',
  Hindu = 'Hindu',
  Freethinker = 'Freethinker',
  Soka = 'Soka',
  OtherReligions = 'Other Religion(s)',
}
