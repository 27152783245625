import { CompanyFilter } from './state';

export enum ActionTypes {
  SET_COMPANY_FILTER = 'SET_COMPANY_FILTER',
  RESET_COMPANY_FILTER = 'RESET_COMPANY_FILTER',
}

export interface BaseAction {
  type: ActionTypes;
}

export interface SetCompanyFilterAction extends BaseAction {
  type: ActionTypes.SET_COMPANY_FILTER;
  companyFilter: CompanyFilter;
}

export interface ResetCompanyFilterAction extends BaseAction {
  type: ActionTypes.RESET_COMPANY_FILTER;
}

export type Actions = SetCompanyFilterAction | ResetCompanyFilterAction;
