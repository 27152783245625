import { Box } from '@mui/material';
import * as React from 'react';
import { CommonMetaHeader } from '../custom/components/common-meta-header';
import { JsonLDTag, JsonLDType } from '../custom/components/json-ld-tag';
import { companyArr } from '../custom/data/company';
import MainLayout from '../custom/layout/main-layout';
import { Company } from '../custom/models/company.model';
import CompanyListing from '../custom/module/main/company-listing';

export const Head = () => {
  return (
    <>
      <CommonMetaHeader />
      <JsonLdListing />
    </>
  );
};

const companyToJsonLdOrganisation = (company: Company) => {
  const addressTxt = [
    company.address1,
    company.address2,
    company.postalCode &&
      `${company.tags?.includes('Singapore') ? 'Singapore ' : ''}${
        company.postalCode
      }`,
  ]
    .filter((x) => x && x.length > 0)
    .join(' ');

  const data: Record<string, any> = {
    '@type': 'Service',
    name: company.name,
  };

  if (addressTxt.length > 0) {
    data.areaServed = {
      '@type': 'Place',
      address: addressTxt,
    };
  }

  if (company.phones?.length > 0) {
    data.availableChannel = {
      '@type': 'ServiceChannel',
      servicePhone: company.phones.map((p) => {
        const telHref =
          (p.phoneCountryCode
            ? '+' + p.phoneCountryCode
            : p.phoneNum && p.phoneNum.startsWith('1800')
            ? ''
            : '+65') +
          (p.phoneAreaCode ?? '') +
          p.phoneNum;
        return {
          '@type': 'ContactPoint',
          name: p.contactPersonName,
          telephone: telHref,
        };
      }),
    };
  }

  return data;
};

const JsonLdListing = () => {
  return (
    <JsonLDTag
      type={JsonLDType.Custom}
      data={{
        '@context': 'https://schema.org/',
        '@type': 'WebSite',
        name: 'Singapore Funeral Directory',
        url: 'https://singaporefuneraldirectory.org',
        description:
          'Singapore Funeral Directory - Helping Families to choose from the List of 24-Hour Funeral Services, Funeral Directors, Funeral Wake, Funeral Parlour in Singapore.',
        alternateName: 'SFD',
        mainEntity: {
          '@type': 'Service',
          serviceType: 'Funeral Services',
          areaServed: {
            '@type': 'Country',
            name: 'Singapore',
          },
          availableChannel: {
            '@type': 'ServiceChannel',
            servicePhone: {
              '@type': 'ContactPoint',
              telephone: '92366269',
            },
            serviceSmsNumber: {
              '@type': 'ContactPoint',
              telephone: '92366269',
            },
          },
          hasOfferCatalog: {
            '@type': 'OfferCatalog',
            itemListElement: companyArr
              .filter((c) => c.verified)
              .map((c) => companyToJsonLdOrganisation(c)),
          },
        },
      }}
    />
  );
};

// markup
const IndexPage = () => {
  return (
    <div>
      <MainLayout>
        {/* <Box m={1} mt={3}>
          <CompanyListingFilter></CompanyListingFilter>
        </Box> */}
        <Box m={1} mt={4}>
          <CompanyListing></CompanyListing>
        </Box>
      </MainLayout>
    </div>
  );
};

export default IndexPage;
