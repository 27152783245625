import { AppBar, Box, Toolbar, Typography } from '@mui/material';
import React, { ReactElement } from 'react';
import GtagNavigate from '../../components/gtag-navigate';
import { CustomGtagCategory } from '../../constants/gtag-category';
// import LogoIcon from '../../../images/ic-logo.svg';
import * as styles from './header.module.scss';

const Header = (): ReactElement => {
  return (
    <>
      {/* <AppBar position="fixed">
      <Toolbar>
        <LogoIcon />
      </Toolbar>
    </AppBar> */}
      <Box
        display="flex"
        justifyContent="center"
        flexWrap="wrap"
        padding={1}
        bgcolor="#22aaff"
      >
        <Box marginX={1}>
          <GtagNavigate
            className={styles.headerLinks}
            category={CustomGtagCategory.About_Us_Link}
            to="/about-sfd/"
          >
            <span>About SFD</span>
          </GtagNavigate>
        </Box>

        <Box marginX={1}>
          <GtagNavigate
            className={styles.headerLinks}
            category={CustomGtagCategory.Family_Link}
            to="/family/"
          >
            <span>For Family</span>
          </GtagNavigate>
        </Box>

        <Box marginX={1}>
          <GtagNavigate
            className={styles.headerLinks}
            category={CustomGtagCategory.Funeral_Director_Link}
            to="/funeral-director/"
          >
            <span>For Funeral Director</span>
          </GtagNavigate>
        </Box>
      </Box>
      <Box display="flex" pb={2} pt={4}>
        <Box width="100%" height="100%" textAlign="center">
          {/* <LogoIcon /> */}
          <Typography variant="h1">Singapore Funeral Directory</Typography>

          <Box mt={3}>
            <Typography variant="h2">
              Helping Families to look for Reliable 24-Hour Funeral Services in
              Singapore
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Header;
