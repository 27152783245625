import slugify from 'slugify';
import { Company } from '../models/company.model';

const srcCompanyArr: Array<Omit<Company, 'nameSlug'>> = [
  {
    name: 'Indian Casket Services',
    address1: 'No 18 Mandai Estate',
    address2: '#06-06',
    postalCode: '729910',
    companyImg: {
      fileName: 'indian_casket_services',
      mode: 'square',
    },
    facebookLinkPath: 'IndianCasketServices',
    tags: ['Singapore', 'OtherReligions', 'Hindu'],
    verified: true,
    phones: [
      {
        phoneCountryCode: '65',
        phoneNum: '91134343',
        hasWhatsapp: true,
        contactPersonName: 'Durga R.',
        isFemale: true,
      },
      {
        phoneCountryCode: '65',
        phoneNum: '93222424',
        hasWhatsapp: true,
        contactPersonName: 'Tambi K.',
      },
    ],
    speciality: ['Indian', 'Hindu'],
    fromPrice: 3500,
    readMoreUrl:
      'https://www.singaporefuneraldirector.com/2021/07/indian-casket-services.html',
  },
];

// Process to append nameSlug field here
export const companyArr: Array<Company> = srcCompanyArr.map((c) => {
  c['nameSlug'] = slugify(c.name, {
    trim: true,
    lower: true,
    remove: /\(.+\)/g, // Regex remove brackets
  });
  return c as Company;
}).sort((a, b) => {
  // sort by company name
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
});
