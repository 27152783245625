export interface OptimizeImageDataItem {
  width: number;
  suffix: string;
  scale: number;
  crop?: boolean;
}

// #region Square image optimization
const BASE_OPTIMIZED_IMAGE_SQUARE_WIDTH = 64; // 4rem
const OPTIMIZE_SQUARE_SCALE_COUNT = 3; // 1x.. 2x.. 3x..
const OPTIMIZE_SQUARE_SCALE_STEP = 1;

export const OPTIMIZE_SQUARE_IMAGE_DATA: Array<OptimizeImageDataItem> = [];
for (
  let s = 1, k = 1;
  k <= OPTIMIZE_SQUARE_SCALE_COUNT;
  k++, s += OPTIMIZE_SQUARE_SCALE_STEP
) {
  const width = Math.ceil(BASE_OPTIMIZED_IMAGE_SQUARE_WIDTH * s);
  OPTIMIZE_SQUARE_IMAGE_DATA.push({
    width,
    suffix: `-${s}x`,
    scale: s,
  });
}
// #endregion

// #region Portrait image optimization
const BASE_OPTIMIZED_IMAGE_PORTRAIT_WIDTH = 64; // 4rem
const OPTIMIZE_PORTRAIT_SCALE_COUNT = 3; // 1x.. 2x.. 3x..
const OPTIMIZE_PORTRAIT_SCALE_STEP = 1;

export const OPTIMIZE_PORTRAIT_IMAGE_DATA: Array<OptimizeImageDataItem> = [];
for (
  let s = 1, k = 1;
  k <= OPTIMIZE_PORTRAIT_SCALE_COUNT;
  k++, s += OPTIMIZE_PORTRAIT_SCALE_STEP
) {
  const width = Math.ceil(BASE_OPTIMIZED_IMAGE_PORTRAIT_WIDTH * s);
  OPTIMIZE_PORTRAIT_IMAGE_DATA.push({
    width,
    suffix: `-${s}x`,
    scale: s,
    crop: true,
  });
}
// #endregion


// #region Rectangle image optimization

// Image renders
// 270.6 325.7 375.78 334.04 291.47 310.04 405.27

// Sorted
// [270.6, 291.47, 310.04, 325.7, 334.04, 375.78, 405.27]

// Scaled
// [1, 1.0771249076127125, 1.145750184774575, 1.203621581670362, 1.2344419807834441, 1.388691796008869, 1.497671840354767]

// Stepper
// [0, 0.07712490761271251, 0.06862527716186251, 0.05787139689578691, 0.03082039911308221, 0.1542498152254248, 0.10898004434589814]

const BASE_OPTIMIZED_IMAGE_RECTANGLE_WIDTH = 270; // This the smallest which is rendered
const OPTIMIZE_RECTANGLE_SCALE_COUNT = 4;
const OPTIMIZE_RECTANGLE_SCALE_STEP = 0.5;

export const OPTIMIZE_RECTANGLE_IMAGE_DATA: Array<OptimizeImageDataItem> = [];
for (
  let s = 1, k = 1;
  k <= OPTIMIZE_RECTANGLE_SCALE_COUNT;
  k++, s += OPTIMIZE_RECTANGLE_SCALE_STEP
) {
  const width = Math.ceil(BASE_OPTIMIZED_IMAGE_RECTANGLE_WIDTH * s);
  OPTIMIZE_RECTANGLE_IMAGE_DATA.push({
    width,
    suffix: `-${s}x`,
    scale: s,
  });
}

// #endregion
