import React, {
  createContext,
  Dispatch,
  ReactElement,
  useReducer,
} from 'react';
import { Actions } from './actions';
import { Reducer } from './reducer';
import { InitialState, State } from './state';

export const Context = createContext<[State, Dispatch<Actions>]>([
  InitialState,
  null,
]);

export const Store = ({ children }): ReactElement => {
  const [state, dispatch] = useReducer(Reducer, InitialState);

  return (
    <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>
  );
};
