import { Box, createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { CacheProvider } from '@emotion/react';
import React, { PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import Footer from '../module/main/footer';
import Header from '../module/main/header';
import { Store } from '../store';
import createEmotionCache from '../helper/create-emotion-cache';

const theme = createTheme({
  typography: {
    fontFamily: 'Cabin Condensed, sans-serif',
    // h1 for page title
    h1: {
      fontSize: '28px',
      fontWeight: 'bold',
    },
    // h2 for page subtitle
    h2: {
      fontSize: '16px',
      fontWeight: 'normal',
    },
    // h5 for company name
    h5: {
      fontSize: '26px',
    },
    // body1 for normal text
    body1: {
      fontSize: '18px',
      lineHeight: '26px',
    },
    // body2 for pill text
    body2: {
      fontSize: '14px',
    },
    // h6 for footer text
    h6: {
      fontSize: '16px',
      lineHeight: '20px',
    },
  },
});

const EmotionProviders = (
  props: PropsWithChildren<{ styleContainer: React.RefObject<HTMLDivElement> }>
): ReactElement => {
  const { styleContainer, children } = props;
  const cache = React.useRef(
    createEmotionCache({ container: styleContainer.current || undefined })
  );
  return (
    <CacheProvider value={cache.current}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </CacheProvider>
  );
};

const MainLayout = ({ children }): ReactElement => {
  const styleContainer = React.createRef<HTMLDivElement>();
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    if (styleContainer.current) {
      setTimeout(() => {
        setShowContent(true);
      }, 1); // timer so that the crazy emotion css does not prepend above meta headers
    }
  }, [styleContainer]);

  return (
    <>
      {/* Load the emotion styles in body */}
      <div ref={styleContainer}></div>
      {showContent && (
        <EmotionProviders styleContainer={styleContainer}>
          <CssBaseline />
          <Store>
            <Header></Header>
            <Box>{children}</Box>
            <Footer></Footer>
          </Store>
        </EmotionProviders>
      )}
    </>
  );
};

export default MainLayout;
