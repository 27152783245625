import {
  Box,
  Card,
  CardActions,
  CardContent,
  IconButton,
  Typography,
  Grid,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { ReactElement, useContext } from 'react';
import { Company, CompanyPhone } from '../models/company.model';
import ContactNum from './contact-num';
import RoomIcon from '@mui/icons-material/Room';
import PhoneIcon from '@mui/icons-material/Phone';
import PublicIcon from '@mui/icons-material/Public';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import { CustomGtagCategory } from '../constants/gtag-category';
import GtagAchor from './gtag-anchor';
import { Context } from '../store';
import Pill, { PillData } from './pill';
import { PreferenceKeyLabelMap } from '../constants/preference';
import {
  OPTIMIZE_PORTRAIT_IMAGE_DATA,
  OPTIMIZE_RECTANGLE_IMAGE_DATA,
  OPTIMIZE_SQUARE_IMAGE_DATA,
} from '../constants/image-optimization';
import { generateScaleSrcSet } from '../helper/image';
import GirlIcon from '@mui/icons-material/Girl';
import DoneIcon from '@mui/icons-material/Done';

// margin top because icons will not be vertically aligned.
// Because it looks ugly if the address wraps to multiline and the icon is centered
const smallIconStyle: React.CSSProperties = {
  fontSize: '16px',
  height: '16px',
  marginRight: '5px',
  marginTop: '5px',
};

const CompanyCard = (
  props: { className?: string } & Partial<Company>
): ReactElement => {
  const theme = useTheme();
  const [state, dispatch] = useContext(Context);
  const addressArr = [
    props.address1,
    props.address2,
    props.postalCode &&
      `${props.tags?.includes('Singapore') ? 'Singapore ' : ''}${
        props.postalCode
      }`,
  ].filter((x) => x && x.length > 0);

  const hasActions = [props.website].some((x) => x);

  const renderTypographyCompanyName = (): React.ReactNode => (
    <Typography
      variant="h5"
      component="h2"
      sx={{
        WebkitAlignSelf: 'center',
      }}
    >
      <span style={{ marginInlineEnd: '10px' }}>{props.name}</span>
      {props.verified && (
        <span
          style={{
            display: 'inline-block',
            border: '1px solid #5995A6',
            borderRadius: '8px',
            padding: '4px 6px',
          }}
        >
          <div
            style={{
              color: '#5995A6',
              display: 'flex',
              flexWrap: 'nowrap',
              alignItems: 'center',
            }}
          >
            <DoneIcon style={{ width: '18px', height: '18px' }} />
            <div
              style={{
                fontSize: '20px',
                lineHeight: '20px',
                marginInlineStart: '2px',
              }}
            >
              Verified
            </div>
          </div>
        </span>
      )}
    </Typography>
  );

  const renderCompanyLogoAndName = (): React.ReactNode => {
    const gutterBottom = '.35rem';

    if (props.companyImg) {
      if (
        props.companyImg.mode === 'square' ||
        props.companyImg.mode === 'portrait'
      ) {
        const shape = props.companyImg.mode;
        const optimizedImgData =
          shape === 'square'
            ? OPTIMIZE_SQUARE_IMAGE_DATA
            : OPTIMIZE_PORTRAIT_IMAGE_DATA;
        const [srcSet, src] = generateScaleSrcSet(
          shape,
          optimizedImgData,
          props.companyImg?.fileName
        );

        // Square logo (regardless square or portrait)
        return (
          <Box display="flex" marginBottom={gutterBottom}>
            <img
              // Do not read image alt
              aria-hidden
              srcSet={srcSet}
              src={src}
              alt={props.name}
              style={{
                flex: '0 0 auto',
                display: 'inline-block',
                marginRight: '5px',
                height: '4rem', // Configure this in image-optimization.js as well
                width: '4rem',
              }}
            />
            {renderTypographyCompanyName()}
          </Box>
        );
      } else if (props.companyImg.mode === 'rectangle') {
        const [srcSet, src] = generateScaleSrcSet(
          'rectangle',
          OPTIMIZE_RECTANGLE_IMAGE_DATA,
          props.companyImg?.fileName
        );
        // Rectangle Logo
        return (
          <Box marginBottom={gutterBottom}>
            <img
              // Do not read image alt
              aria-hidden
              srcSet={srcSet}
              src={src}
              alt={`${props.name} Logo`}
              style={{
                objectFit: 'contain',
                display: 'block',
                maxWidth: '100%',
                height: '4rem',
                marginBottom: '5px',
              }}
            />
            {renderTypographyCompanyName()}
          </Box>
        );
      }
    }

    // No logo or unknown img mode
    return (
      <Box display="flex" marginBottom={gutterBottom}>
        {renderTypographyCompanyName()}
      </Box>
    );
  };

  const cardMinHeight = useMediaQuery(theme.breakpoints.up('sm'))
    ? '310px'
    : '200px';

  const getWhatsappText = (phone: CompanyPhone): string => {
    if (!(phone.phoneNum && phone.hasWhatsapp)) {
      return '';
    } else {
      let whatsappText: string = '';
      if (
        typeof phone.hasWhatsapp !== 'boolean' &&
        phone.hasWhatsapp.customWhatsappMessage
      ) {
        if (state.companyFilter.preference) {
          whatsappText =
            phone.hasWhatsapp.customWhatsappMessage[
              state.companyFilter.preference
            ] || '';
        }

        // Ovewrite with default message
        if (whatsappText == null || whatsappText.length === 0) {
          whatsappText =
            phone.hasWhatsapp.customWhatsappMessage['default'] || '';
        }
      }

      if (whatsappText == null || whatsappText.length === 0) {
        whatsappText = `Hi, I would like to contact ${props.name} to find out more about funeral services`;
      }

      return whatsappText;
    }
  };

  // https://www.eggradients.com/
  const pills: Array<{ enabled: boolean; pillData: PillData }> = [
    // {
    //   enabled: props.verified,
    //   pillData: {
    //     text: 'Verified',
    //     textColor: '#ffffff',
    //     backgroundImage: 'linear-gradient(315deg, #70b2d9 0%, #39e5b6 74%)',
    //     fallbackBgColor: '#70b2d9',
    //   },
    // },
  ];

  let specialityDisplay: string = '';
  if (props.speciality) {
    if (Array.isArray(props.speciality)) {
      if (props.speciality.length > 0) {
        specialityDisplay = `${props.speciality
          .map((s) => PreferenceKeyLabelMap[s])
          .join(', ')} Funeral`;
      }
    } else {
      specialityDisplay = props.speciality;
    }
  }

  let priceDisplay: string = '';
  if (props.fromPrice) {
    priceDisplay = `From $${props.fromPrice.toLocaleString()}`;
  }

  const phoneArr = props.phones || [];

  return (
    <Card variant="outlined">
      <Box minHeight={cardMinHeight}>
        <CardContent>
          {/** Company logo and name */}
          {renderCompanyLogoAndName()}

          {/** Address */}
          {addressArr.length > 0 && (
            <Box display="flex" flexDirection="row" alignItems="start">
              <div aria-label="Address">
                <RoomIcon style={smallIconStyle} />
              </div>

              <Typography>{addressArr.join(' ')}</Typography>
            </Box>
          )}

          {/** Phone */}
          {phoneArr.length === 0 ? (
            <Box display="flex" flexDirection="row">
              <div aria-label="Contact">
                <PhoneIcon style={smallIconStyle} />
              </div>

              <Typography>Coming Soon</Typography>
            </Box>
          ) : (
            phoneArr.map((p) => (
              <Box key={p.phoneNum} display="flex" flexDirection="row">
                <PhoneIcon style={smallIconStyle} />
                <Box display="flex" flexDirection="row" flexWrap="wrap">
                  {p.contactPersonName && (
                    <Typography style={{ marginInlineEnd: '.2rem' }}>
                      {p.contactPersonName}
                    </Typography>
                  )}
                  <Typography component="div">
                    <ContactNum
                      phoneCountryCode={p.phoneCountryCode}
                      phoneAreaCode={p.phoneAreaCode}
                      phoneNumber={p.phoneNum}
                      hasWhatsapp={!!p.hasWhatsapp}
                      whatsappText={getWhatsappText(p)}
                      phoneNumGtagCategory={
                        CustomGtagCategory.Company_Phone_Link
                      }
                      phoneNumGtagLabel={props.name}
                      whatsappGtagCategory={
                        CustomGtagCategory.Company_Whatsapp_Link
                      }
                      whatsappGtagLabel={props.name}
                      whatsappAriaLabel={`Click here to WhatsApp ${
                        p.contactPersonName || props.name
                      }`}
                    ></ContactNum>
                  </Typography>
                  {p.isFemale && (
                    <div aria-label="Female Director" title="Female Director">
                      <GirlIcon />
                    </div>
                  )}
                </Box>
              </Box>
            ))
          )}

          {/** Specialty */}
          {specialityDisplay && (
            <Box display="flex" flexDirection="row">
              <div aria-label="Specialty">
                <LocalActivityIcon style={smallIconStyle} />
              </div>

              <Typography>{specialityDisplay}</Typography>
            </Box>
          )}

          {/** Price Tag */}
          {priceDisplay && (
            <Box display="flex" flexDirection="row">
              <div aria-label="Price">
                <MonetizationOnIcon style={smallIconStyle} />
              </div>

              <Typography>{priceDisplay}</Typography>
            </Box>
          )}

          {/** Read more Detail Page (deprecated) */}
          {/* {props.details && (
            <Box display="flex" flexDirection="row">
              <FindInPageIcon style={smallIconStyle} />
              <Typography>
                <GtagNavigate
                  category={CustomGtagCategory.Company_Read_More_Navigate}
                  label={props.name}
                  to={`/company/${props.nameSlug}`}
                >
                  Read More
                </GtagNavigate>
              </Typography>
            </Box>
          )} */}

          {/** Read more Link */}
          {/* {props.readMoreUrl && (
            <Box display="flex" flexDirection="row">
              <FindInPageIcon style={smallIconStyle} />
              <Typography>
                <GtagAchor
                  href={props.readMoreUrl}
                  category={CustomGtagCategory.Company_Read_More_Link}
                  label={props.name}
                >
                  Read More
                </GtagAchor>
              </Typography>
            </Box>
          )} */}

          {pills.some((x) => x.enabled) && (
            <Box paddingTop="10px">
              <Grid container spacing={1}>
                {pills.map(
                  (p) =>
                    p.enabled && (
                      <Grid key={p.pillData.text} item>
                        <Pill {...p.pillData} />
                      </Grid>
                    )
                )}
              </Grid>
            </Box>
          )}
        </CardContent>
      </Box>

      {/** Others */}
      {hasActions && (
        <>
          <p style={{ height: 20 }}></p>
          <CardActions disableSpacing>
            {props.website && (
              <IconButton
                aria-label="Website"
                href={props.website}
                target="_blank"
                size="large"
              >
                <PublicIcon />
              </IconButton>
            )}
          </CardActions>
        </>
      )}
    </Card>
  );
};

export default CompanyCard;
