import { PreferenceKeys } from '../constants/preference';

export interface CompanyFilter {
  preference?: PreferenceKeys;
}

export interface State {
  companyFilter: CompanyFilter;
}

export const InitialState: State = {
  companyFilter: {},
};
